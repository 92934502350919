import React, { Fragment } from 'react';
import { Link } from 'gatsby';

import BahaideNavigation from './BahaideNavigation';
import TopNavigation from './TopNavigation';
import HausDerAndachtNavigation from './HausDerAndachtNavigation';
import AktuellesNavigation from './AktuellesNavigation';
import BicentenaryNavigation from './BicentenaryNavigation';

function Header({ site, siteTitle, environment }) {
    return (
        <Fragment>
            <TopNavigation site={site} environment={environment} />
            <header id="header">
                <div className="container">
                    <div className="header-holder">
                        <div className="logo">
                            {site === 'bahaide' ? (
                                <Link to="/">{siteTitle}</Link>
                            ) : site === 'aktuelles' ? (
                                <Link to="/">{siteTitle}</Link>
                            ) : site === 'hda' ? (
                                <Link to="/haus-der-andacht/">
                                    Haus der Andacht
                                </Link>
                            ) : site === 'bicentenary' ? (
                                <Link to="/">200 Jahrfeier</Link>
                            ) : null}
                        </div>
                        <form
                            method="get"
                            className="search-form"
                            action="https://www.bahai.de/search"
                        >
                            <fieldset>
                                <input
                                    type="search"
                                    name="q"
                                    placeholder=""
                                    defaultValue=""
                                />
                                <button>
                                    <i
                                        className="fa fa-search fa-rotate-90"
                                        aria-hidden="true"
                                    />
                                </button>
                            </fieldset>
                        </form>
                        {site === 'bahaide' ? (
                            <BahaideNavigation />
                        ) : site === 'aktuelles' ? (
                            <AktuellesNavigation />
                        ) : site === 'hda' ? (
                            <HausDerAndachtNavigation />
                        ) : site === 'bicentenary' ? (
                            <BicentenaryNavigation />
                        ) : null}
                    </div>
                </div>
            </header>
        </Fragment>
    );
}

export default Header;
