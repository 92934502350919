import React from 'react';
import { Link } from 'gatsby';
import { Location } from '@reach/router';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const TopNavigation = ({ site, environment }) => (
    <nav className="top-navigation">
        <ul className="container">
            <Location>
                {({ location }) => (
                    <>
                        <li className={site === 'bahaide' ? 'active' : ''}>
                            {environment === "development" ? (
                                <a href="http://localhost:8000">
                                    Bahá’í in Deutschland
                                </a>
                            ) : (
                                <OutboundLink href="https://www.bahai.de/">
                                    Bahá’í in Deutschland
                                </OutboundLink>
                            )}
                            
                        </li>
                        <li className={site === 'aktuelles' ? 'active' : ''}>
                            {environment === "development" ? (
                                <a href="http://localhost:8001">
                                    Aktuelles
                                </a>
                            ) : (
                                <OutboundLink href="https://aktuelles.bahai.de/">
                                    Aktuelles
                                </OutboundLink>
                            )}
                        </li>
                        <li className={site === 'hda' ? 'active' : ''}>
                            {environment === "development" ? (
                                <a href="http://localhost:8000/haus-der-andacht/">
                                    Haus der Andacht
                                </a>
                            ) : (
                                <OutboundLink href="https://www.bahai.de/haus-der-andacht/">
                                    Haus der Andacht
                                </OutboundLink>
                            )}
                        </li>
                        <li>
                                <OutboundLink href="https://bibliothek.bahai.de/">
                                    Bibliothek
                                </OutboundLink>
                        </li>
                    </>
                )}
            </Location>
        </ul>
    </nav>
);

export default TopNavigation;
