import React, { Component } from 'react';
import { Link } from 'gatsby';
import { NavDropHolder } from './BahaideNavigation';
import Helmet from 'react-helmet';

class HausDerAndachtNavigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileNav: false,
        };
    }
    _toggleNav = e => {
        e.preventDefault();
        this.setState({
            mobileNav: !this.state.mobileNav,
        });
    };
    render() {
        return (
            <>
                <Helmet>
                    <body
                        className={this.state.mobileNav ? 'nav-active' : ''}
                    />
                </Helmet>
                <div className="nav-holder">
                    <a
                        href="#"
                        onClick={e => this._toggleNav(e)}
                        className="nav-opener"
                    />
                    <div className="nav-drop">
                        <ul id="nav" className="menu">
                            <li>
                                <Link to="/haus-der-andacht/andacht-und-gebet/">
                                    Andacht und Gebet
                                </Link>
                                <NavDropHolder>
                                    <Link
                                        className="link"
                                        to="/haus-der-andacht/andacht-und-gebet/"
                                    >
                                        Andacht und Gebet
                                    </Link>
                                </NavDropHolder>
                            </li>
                            <li>
                                <Link to="/haus-der-andacht/architektur/">
                                    Architektur
                                </Link>
                                <NavDropHolder>
                                    <Link
                                        className="link"
                                        to="/haus-der-andacht/architektur/"
                                    >
                                        Architektur
                                    </Link>
                                </NavDropHolder>
                            </li>
                            <li>
                                <Link to="/haus-der-andacht/besucherinformationen/">
                                Besucherinformationen
                                </Link>
                                <NavDropHolder>
                                    <Link
                                        className="link"
                                        to="/haus-der-andacht/besucherinformationen/"
                                    >
                                        Besucherinformationen
                                    </Link>
                                </NavDropHolder>
                            </li>
                            <li>
                                <Link to="/haus-der-andacht/kontakt/">
                                Kontakt
                                </Link>
                                <NavDropHolder>
                                    <Link
                                        className="link"
                                        to="/haus-der-andacht/kontakt/"
                                    >
                                        Kontakt
                                    </Link>
                                </NavDropHolder>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

export default HausDerAndachtNavigation;
