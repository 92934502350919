import React, { Fragment, Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

class BahaideNavigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileNav: false,
            wbg_hover: false,
            wbg_bsa_hover: false,
            wbg_ndm_hover: false,
            wbg_gdm_hover: false,
            wbg_edg_hover: false,
            gh_hover: false,
            gh_gl_hover: false,
            gh_ad_hover: false,
            gh_fk_hover: false,
            gh_j_hover: false,
            gh_bd_hover: false,
        };
    }

    _toggleNav = e => {
        e.preventDefault();
        this.setState({
            mobileNav: !this.state.mobileNav,
        });
    };

    _hoverOn = (area, e) => {
        e.preventDefault();
        switch (area) {
            case 'wbg':
                this.setState({
                    wbg_hover: !this.state.wbg_hover,
                    gh_hover: false,
                    wbg_bsa_hover: false,
                    wbg_ndm_hover: false,
                    wbg_gdm_hover: false,
                    wbg_edg_hover: false,
                });
                break;
            case 'wbg_bsa':
                this.setState({
                    wbg_hover: true,
                    wbg_bsa_hover: true,
                    wbg_ndm_hover: false,
                    wbg_gdm_hover: false,
                    wbg_edg_hover: false,
                });
                break;
            case 'wbg_ndm':
                this.setState({
                    wbg_hover: true,
                    wbg_ndm_hover: true,
                    wbg_bsa_hover: false,
                    wbg_gdm_hover: false,
                    wbg_edg_hover: false,
                });
                break;
            case 'wbg_gdm':
                this.setState({
                    wbg_hover: true,
                    wbg_gdm_hover: true,
                    wbg_bsa_hover: false,
                    wbg_ndm_hover: false,
                    wbg_edg_hover: false,
                });
                break;
            case 'wbg_edg':
                this.setState({
                    wbg_hover: true,
                    wbg_edg_hover: true,
                    wbg_bsa_hover: false,
                    wbg_ndm_hover: false,
                    wbg_gdm_hover: false,
                });
                break;
            case 'gh':
                this.setState({
                    gh_hover: !this.state.gh_hover,
                    wbg_hover: false,
                    gh_gl_hover: false,
                    gh_ad_hover: false,
                    gh_fk_hover: false,
                    gh_j_hover: false,
                    gh_bd_hover: false,
                });
                break;
            case 'gh_gl':
                this.setState({
                    gh_hover: true,
                    gh_gl_hover: true,
                    gh_ad_hover: false,
                    gh_fk_hover: false,
                    gh_j_hover: false,
                    gh_bd_hover: false,
                });
                break;
            case 'gh_ad':
                this.setState({
                    gh_hover: true,
                    gh_ad_hover: true,
                    gh_gl_hover: false,
                    gh_fk_hover: false,
                    gh_j_hover: false,
                    gh_bd_hover: false,
                });
                break;
            case 'gh_fk':
                this.setState({
                    gh_hover: true,
                    gh_fk_hover: true,
                    gh_gl_hover: false,
                    gh_ad_hover: false,
                    gh_j_hover: false,
                    gh_bd_hover: false,
                });
                break;
            case 'gh_j':
                this.setState({
                    gh_hover: true,
                    gh_j_hover: true,
                    gh_gl_hover: false,
                    gh_ad_hover: false,
                    gh_fk_hover: false,
                    gh_bd_hover: false,
                });
                break;
            case 'gh_bd':
                this.setState({
                    gh_hover: true,
                    gh_bd_hover: true,
                    gh_gl_hover: false,
                    gh_ad_hover: false,
                    gh_j_hover: false,
                    gh_fk_hover: false,
                });
                break;
            default:
                this.setState({
                    wbg_hover: false,
                    gh_hover: false,
                });
        }
    };

    _hoverOff = (area, e) => {
        e.preventDefault();
        switch (area) {
            case 'wbg':
                this.setState({
                    wbg_hover: false,
                });
                break;
            case 'wbg_bsa':
                this.setState({
                    wbg_bsa_hover: false,
                });
                break;
            case 'wbg_ndm':
                this.setState({
                    wbg_ndm_hover: false,
                });
                break;
            case 'wbg_gdm':
                this.setState({
                    wbg_gdm_hover: false,
                });
                break;
            case 'wbg_edg':
                this.setState({
                    wbg_edg_hover: false,
                });
                break;
            case 'gh':
                this.setState({
                    gh_hover: false,
                });
                break;
            case 'gh_gl':
                this.setState({
                    gh_gl_hover: false,
                });
                break;
            case 'gh_ad':
                this.setState({
                    gh_ad_hover: false,
                });
                break;
            case 'gh_fk':
                this.setState({
                    gh_fk_hover: false,
                });
                break;
            case 'gh_j':
                this.setState({
                    gh_j_hover: false,
                });
                break;
            case 'gh_bd':
                this.setState({
                    gh_bd_hover: false,
                });
                break;
            default:
                this.setState({
                    wbg_hover: false,
                    gh_hover: false,
                });
        }
    };

    render() {
        return (
            <Fragment>
                <Helmet>
                    <body
                        className={this.state.mobileNav ? 'nav-active' : ''}
                    />
                </Helmet>
                <div className="nav-holder">
                    <a
                        href="#"
                        onClick={e => this._toggleNav(e)}
                        className="nav-opener"
                    />
                    <div className="nav-drop">
                        <ul id="nav" className="menu">
                            <li className={this.state.wbg_hover ? 'hover' : ''}>
                                <Link
                                    to="/woran-bahai-glauben/"
                                    onClick={e => this._hoverOn('wbg', e)}
                                >
                                    Woran Bahá’í glauben
                                </Link>
                                <NavDropHolder
                                    action={e => this._hoverOff('wbg', e)}
                                >
                                    <Link
                                        className="link"
                                        to="/woran-bahai-glauben/"
                                    >
                                        Woran Bahá’í glauben
                                    </Link>
                                    <ul>
                                        <li className="mobile-hidden">
                                            <Link to="/woran-bahai-glauben/">
                                                Woran Bahá’í glauben
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                this.state.wbg_bsa_hover
                                                    ? 'hover'
                                                    : ''
                                            }
                                        >
                                            <Link
                                                to="/woran-bahai-glauben/bahaullah-und-sein-anspruch/"
                                                onClick={e =>
                                                    this._hoverOn('wbg_bsa', e)
                                                }
                                            >
                                                Bahá’u’lláh und Sein Anspruch
                                            </Link>
                                            <NavDropHolder
                                                action={e =>
                                                    this._hoverOff('wbg_bsa', e)
                                                }
                                            >
                                                <Link
                                                    className="link"
                                                    to="/woran-bahai-glauben/bahaullah-und-sein-anspruch/"
                                                >
                                                    Bahá’u’lláh und Sein
                                                    Anspruch
                                                </Link>
                                                <ul>
                                                    <li className="mobile-hidden">
                                                        <Link to="/woran-bahai-glauben/bahaullah-und-sein-anspruch/">
                                                            Bahá’u’lláh und Sein
                                                            Anspruch
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/woran-bahai-glauben/bahaullah-und-sein-anspruch/bahaullah/">
                                                            Bahá’u’lláh
                                                            (1817-1892)
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/woran-bahai-glauben/bahaullah-und-sein-anspruch/der-bab/">
                                                            Der Báb (1819-1850)
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/woran-bahai-glauben/bahaullah-und-sein-anspruch/abdul-baha/">
                                                            ‘Abdu’l-Bahá
                                                            (1844-1921)
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/woran-bahai-glauben/bahaullah-und-sein-anspruch/shoghi-effendi/">
                                                            Shoghi Effendi
                                                            (1897-1957)
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/woran-bahai-glauben/bahaullah-und-sein-anspruch/das-universale-haus-der-gerechtigkeit/">
                                                            Das Universale Haus{' '}
                                                            <br />
                                                            der Gerechtigkeit
                                                            (seit 1963)
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </NavDropHolder>
                                        </li>
                                        <li
                                            className={
                                                this.state.wbg_ndm_hover
                                                    ? 'hover'
                                                    : ''
                                            }
                                        >
                                            <Link
                                                to="/woran-bahai-glauben/natur-des-menschen/"
                                                onClick={e =>
                                                    this._hoverOn('wbg_ndm', e)
                                                }
                                            >
                                                Die Natur des Menschen
                                            </Link>
                                            <NavDropHolder
                                                action={e =>
                                                    this._hoverOff('wbg_ndm', e)
                                                }
                                            >
                                                <Link
                                                    className="link"
                                                    to="/woran-bahai-glauben/natur-des-menschen/"
                                                >
                                                    Die Natur des Menschen
                                                </Link>
                                                <ul>
                                                    <li className="mobile-hidden">
                                                        <Link to="/woran-bahai-glauben/natur-des-menschen/">
                                                            Die Natur des
                                                            Menschen
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/woran-bahai-glauben/natur-des-menschen/die-seele/">
                                                            Die Seele
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/woran-bahai-glauben/natur-des-menschen/gebet-und-meditation/">
                                                            Gebet und Meditation
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </NavDropHolder>
                                        </li>
                                        <li
                                            className={
                                                this.state.wbg_gdm_hover
                                                    ? 'hover'
                                                    : ''
                                            }
                                        >
                                            <Link
                                                to="/woran-bahai-glauben/gott-und-der-mensch/"
                                                onClick={e =>
                                                    this._hoverOn('wbg_gdm', e)
                                                }
                                            >
                                                Gott und der Mensch
                                            </Link>
                                            <NavDropHolder
                                                action={e =>
                                                    this._hoverOff('wbg_gdm', e)
                                                }
                                            >
                                                <Link
                                                    className="link"
                                                    to="/woran-bahai-glauben/gott-und-der-mensch/"
                                                >
                                                    Gott und der Mensch
                                                </Link>
                                                <ul>
                                                    <li className="mobile-hidden">
                                                        <Link to="/woran-bahai-glauben/gott-und-der-mensch/">
                                                            Gott und der Mensch
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/woran-bahai-glauben/gott-und-der-mensch/fortschreitende-offenbarung/">
                                                            Fortschreitende
                                                            Offenbarung
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/woran-bahai-glauben/gott-und-der-mensch/fortschreitende-kultur/">
                                                            Fortschreitende
                                                            Kultur
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </NavDropHolder>
                                        </li>
                                        <li
                                            className={
                                                this.state.wbg_edg_hover
                                                    ? 'hover'
                                                    : ''
                                            }
                                        >
                                            <Link
                                                to="/woran-bahai-glauben/der-einzelne-und-die-gesellschaft/"
                                                onClick={e =>
                                                    this._hoverOn('wbg_edg', e)
                                                }
                                            >
                                                Der Einzelne und die
                                                Gesellschaft
                                            </Link>
                                            <NavDropHolder
                                                action={e =>
                                                    this._hoverOff('wbg_edg', e)
                                                }
                                            >
                                                <Link
                                                    className="link"
                                                    to="/woran-bahai-glauben/der-einzelne-und-die-gesellschaft/"
                                                >
                                                    Der Einzelne und die
                                                    Gesellschaft
                                                </Link>
                                                <ul>
                                                    <li className="mobile-hidden">
                                                        <Link to="/woran-bahai-glauben/der-einzelne-und-die-gesellschaft/">
                                                            Der Einzelne und die
                                                            Gesellschaft
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/woran-bahai-glauben/der-einzelne-und-die-gesellschaft/der-einzelne/">
                                                            Der Einzelne
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/woran-bahai-glauben/der-einzelne-und-die-gesellschaft/die-gemeinschaft/">
                                                            Die Gemeinschaft
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/woran-bahai-glauben/der-einzelne-und-die-gesellschaft/die-institutionen/">
                                                            Die Institutionen
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </NavDropHolder>
                                        </li>

                                        {/* Pseudo nav elements to increase height */}
                                        <li className={''}>
                                            <Link>&nbsp;</Link>
                                            <NavDropHolder />
                                        </li>
                                        <li className={''}>
                                            <Link>&nbsp;</Link>
                                            <NavDropHolder />
                                        </li>
                                    </ul>
                                </NavDropHolder>
                            </li>
                            <li className={this.state.gh_hover ? 'hover' : ''}>
                                <Link
                                    to="/gemeinsames-handeln/"
                                    onClick={e => this._hoverOn('gh', e)}
                                >
                                    Gemeinsames Handeln
                                </Link>
                                <NavDropHolder
                                    action={e => this._hoverOff('gh', e)}
                                >
                                    <Link
                                        className="link"
                                        to="/gemeinsames-handeln/"
                                    >
                                        Gemeinsames Handeln
                                    </Link>
                                    <ul>
                                        <li className="mobile-hidden">
                                            <Link to="/gemeinsames-handeln/">
                                                Gemeinsames Handeln
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                this.state.gh_gl_hover
                                                    ? 'hover'
                                                    : ''
                                            }
                                        >
                                            <Link
                                                to="/gemeinsames-handeln/gemeinsames-lernen/"
                                                onClick={e =>
                                                    this._hoverOn('gh_gl', e)
                                                }
                                            >
                                                Gemeinsames Lernen
                                            </Link>
                                            <NavDropHolder
                                                action={e =>
                                                    this._hoverOff('gh_gl', e)
                                                }
                                            >
                                                <Link
                                                    className="link"
                                                    to="/gemeinsames-handeln/gemeinsames-lernen/"
                                                >
                                                    Gemeinsames Lernen
                                                </Link>
                                                <ul>
                                                    <li className="mobile-hidden">
                                                        <Link to="/gemeinsames-handeln/gemeinsames-lernen/">
                                                            Gemeinsames Lernen
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/gemeinsames-handeln/gemeinsames-lernen/das-trainingsinstitut/">
                                                            Das
                                                            Trainingsinstitut
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </NavDropHolder>
                                        </li>
                                        <li
                                            className={
                                                this.state.gh_ad_hover
                                                    ? 'hover'
                                                    : ''
                                            }
                                        >
                                            <Link
                                                to="/gemeinsames-handeln/andacht-und-dienst/"
                                                onClick={e =>
                                                    this._hoverOn('gh_ad', e)
                                                }
                                            >
                                                Andacht und Dienst
                                            </Link>
                                            <NavDropHolder
                                                action={e =>
                                                    this._hoverOff('gh_ad', e)
                                                }
                                            >
                                                <Link
                                                    className="link"
                                                    to="/gemeinsames-handeln/andacht-und-dienst/"
                                                >
                                                    Andacht und Dienst
                                                </Link>
                                                <ul>
                                                    <li className="mobile-hidden">
                                                        <Link to="/gemeinsames-handeln/andacht-und-dienst/">
                                                            Andacht und Dienst
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/gemeinsames-handeln/andacht-und-dienst/andachtsversammlungen/">
                                                            Andachtsversammlungen
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/gemeinsames-handeln/andacht-und-dienst/das-konzept-haus-der-andacht/">
                                                            Das Konzept des{' '}
                                                            <br />
                                                            Bahá’í-Hauses der
                                                            Andacht
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </NavDropHolder>
                                        </li>
                                        <li
                                            className={
                                                this.state.gh_fk_hover
                                                    ? 'hover'
                                                    : ''
                                            }
                                        >
                                            <Link
                                                to="/gemeinsames-handeln/familie-und-kinder/"
                                                onClick={e =>
                                                    this._hoverOn('gh_fk', e)
                                                }
                                            >
                                                Familie und Kinder
                                            </Link>
                                            <NavDropHolder
                                                action={e =>
                                                    this._hoverOff('gh_fk', e)
                                                }
                                            >
                                                <Link
                                                    className="link"
                                                    to="/gemeinsames-handeln/familie-und-kinder/"
                                                >
                                                    Familie und Kinder
                                                </Link>
                                                <ul>
                                                    <li className="mobile-hidden">
                                                        <Link to="/gemeinsames-handeln/familie-und-kinder/">
                                                            Familie und Kinder
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/gemeinsames-handeln/familie-und-kinder/kinderklasse/">
                                                            Kinderklasse
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </NavDropHolder>
                                        </li>
                                        <li
                                            className={
                                                this.state.gh_j_hover
                                                    ? 'hover'
                                                    : ''
                                            }
                                        >
                                            <Link
                                                to="/gemeinsames-handeln/jugend/"
                                                onClick={e =>
                                                    this._hoverOn('gh_j', e)
                                                }
                                            >
                                                Jugend
                                            </Link>
                                            <NavDropHolder
                                                action={e =>
                                                    this._hoverOff('gh_j', e)
                                                }
                                            >
                                                <Link
                                                    className="link"
                                                    to="/gemeinsames-handeln/jugend/"
                                                >
                                                    Jugend
                                                </Link>
                                                <ul>
                                                    <li className="mobile-hidden">
                                                        <Link to="/gemeinsames-handeln/jugend/">
                                                            Jugend
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/gemeinsames-handeln/jugend/arbeit-mit-juniorjugendlichen/">
                                                            Arbeit mit
                                                            Juniorjugendlichen
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </NavDropHolder>
                                        </li>
                                        <li
                                            className={
                                                this.state.gh_bd_hover
                                                    ? 'hover'
                                                    : ''
                                            }
                                        >
                                            <Link
                                                to="/gemeinsames-handeln/beteiligung-an-diskursen/"
                                                onClick={e =>
                                                    this._hoverOn('gh_bd', e)
                                                }
                                            >
                                                Beteiligung an Diskursen
                                            </Link>
                                            <NavDropHolder
                                                action={e =>
                                                    this._hoverOff('gh_bd', e)
                                                }
                                            >
                                                <Link
                                                    className="link"
                                                    to="/gemeinsames-handeln/beteiligung-an-diskursen/"
                                                >
                                                    Beteiligung an Diskursen
                                                </Link>
                                                <ul>
                                                    <li className="mobile-hidden">
                                                        <Link to="/gemeinsames-handeln/beteiligung-an-diskursen/">
                                                            Beteiligung an
                                                            Diskursen
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/gemeinsames-handeln/beteiligung-an-diskursen/religion-in-der-gesellschaft/">
                                                            Religion in der
                                                            Gesellschaft
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </NavDropHolder>
                                        </li>
                                        <li>
                                            <Link to="/gemeinsames-handeln/die-bahai-in-deutschland/">
                                                Die Bahá’í in Deutschland
                                            </Link>
                                        </li>
                                    </ul>
                                </NavDropHolder>
                            </li>
                            <li>
                                <Link to="/bahai-kennenlernen/">
                                    Bahá’í kennenlernen
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export const NavDropHolder = ({ children, action }) => (
    <div className="drop-holder">
        <a className="close" href="#" onClick={action} />
        {children}
    </div>
);

export default BahaideNavigation;
