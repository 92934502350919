import React from 'react';
import { Link } from 'gatsby';
import { Location } from '@reach/router';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const templeLogo = require('../images/temple-logo.svg');

const Footer = () => (
    <footer id="footer">
        <Location>
            {({ location }) => {
                if (!location.pathname.includes('/haus-der-andacht/')) {
                    return (
                        <div className="container">
                            <ul id="menu-footer-nav" className="footer-nav">
                                <li className="menu-item">
                                    <OutboundLink href="https://www.bahai.de/kontakt/">
                                        Kontakt
                                    </OutboundLink>
                                </li>
                                <li className="menu-item">
                                    <OutboundLink href="https://konferenzen.bahai.de/">
                                        Konferenzen
                                    </OutboundLink>
                                </li>
                                <li className="external menu-item">
                                    <OutboundLink href="http://iran.bahai.de/">
                                        Menschenrechte
                                    </OutboundLink>
                                </li>
                                <li className="external menu-item">
                                    <OutboundLink href="https://www.bahai-verlag.de/">
                                        Bahá’í Verlag
                                    </OutboundLink>
                                </li>
                                <li className="external menu-item">
                                    <OutboundLink href="https://www.bahai.org/">
                                        bahai.org
                                    </OutboundLink>
                                </li>
                            </ul>
                            <ul className="link-list">
                                <li>
                                    <OutboundLink href="https://www.bahai.de/haus-der-andacht/besucherinformationen/">
                                        Anfahrt zum Haus der Andacht
                                    </OutboundLink>
                                </li>
                                <li className="style">
                                    <OutboundLink href="https://www.bahai.de/haus-der-andacht/directions/">
                                        Directions to House of Worship
                                    </OutboundLink>
                                </li>
                            </ul>
                        </div>
                    );
                } else {
                    return (
                        <div className="container">
                            <ul id="menu-footer-nav" className="footer-nav">
                                <li className="menu-item menu-item--image mobile-hidden">
                                    <Link to="/haus-der-andacht/">
                                        <img src={templeLogo} alt="" />
                                    </Link>
                                </li>
                                <li className="menu-item">
                                    <Link to="/bahai-kennenlernen/">
                                        Bahá’í Kennenlernen
                                    </Link>
                                </li>
                            </ul>
                            <ul className="link-list">
                                <li className="desktop-hidden">
                                    <Link to="/haus-der-andacht/">
                                        <img src={templeLogo} alt="" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/haus-der-andacht/besucherinformationen/">
                                        Anfahrt zum Haus der Andacht
                                    </Link>
                                </li>
                                <li className="style">
                                    <Link to="/haus-der-andacht/directions/">
                                        Directions to House of Worship
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    );
                }
            }}
        </Location>
        <div className="footer-holder">
            <div className="container">
                <span className="copyright">
                    Offizielle Webseite der Bahá'í-Gemeinde in Deutschland
                    K.d.ö.R.
                </span>
                <ul id="menu-add-footer-nav" className="add-footer-nav">
                    <li className="menu-item">
                        <OutboundLink href="https://www.bahai.de/impressum/">
                            Impressum
                        </OutboundLink>
                    </li>
                    <li className="menu-item">
                        <OutboundLink href="https://www.bahai.de/datenschutzerklaerung/">
                            Datenschutzerklärung
                        </OutboundLink>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
);

export default Footer;
