import React from 'react';
import { Helmet } from 'react-helmet';

import { Location } from '@reach/router';

function SEO({ title, description, metadata, image, article }) {
    return (
        <Location>
            {({ location }) => {
                const {
                    defaultTitle,
                    defaultDescription,
                    twitterUsername,
                    siteUrl,
                    titleTemplate,
                    defaultImage,
                } = metadata;
                const seo = {
                    title: title || defaultTitle,
                    description: description || defaultDescription,
                    image: `${siteUrl}${image || defaultImage}`,
                    url: `${siteUrl}${location.pathname || '/'}`,
                };
                return (
                    <Helmet
                        title={seo.title}
                        titleTemplate={
                            seo.title === "Bahá'í in Deutschland"
                                ? null
                                : titleTemplate
                        }
                    >
                        <html lang="de" />
                        <link rel="canonical" href={seo.url} />
                        <meta property="og:title" content={seo.title} />
                        <meta name="description" content={seo.description} />
                        <meta
                            property="og:description"
                            content={seo.description}
                        />
                        <meta name="image" content={seo.image} />
                        <meta property="og:url" content={seo.url} />
                        {article ? (
                            <meta property="og:type" content="article" />
                        ) : null}
                        <meta property="og:image" content={seo.image} />
                        <meta property="fb:app_id" content="445544043231772" />
                        <meta
                            name="twitter:card"
                            content="summary_large_image"
                        />
                        {twitterUsername ? (
                            <meta
                                name="twitter:creator"
                                content={twitterUsername}
                            />
                        ) : null}
                        <meta name="twitter:title" content={seo.title} />
                        <meta
                            name="twitter:description"
                            content={seo.description}
                        />
                        <meta name="twitter:image" content={seo.image} />
                        <script type="application/ld+json">
                            {`"@context": "http://schema.org", 
                                "@type": "WebSite", 
                                "url": ${seo.url}, 
                                "name": ${seo.title}, 
                                "description": ${seo.description}, 
                                "publisher": { 
                                    "@type": "Organization", 
                                    "name": "Bahá'í-Gemeinde in Deutschland K.d.ö.R.", 
                                    "url": "https://www.bahai.de", 
                                    "logo": { 
                                        "@type": "ImageObject", 
                                        "url": ${seo.image}, 
                                        "width": "1024", 
                                        "height": "1024" 
                                    }
                                },
                                "potentialAction": {
                                    "@type": "SearchAction", 
                                    "target": "https://www.bahai.org/search#query={search_term}", 
                                    "query-input": "required name=search_term" 
                                }
                            `}
                        </script>
                    </Helmet>
                );
            }}
        </Location>
    );
}

export default SEO;
