import React from 'react';
import SEO from './SEO';

import Header from './Header';
import Footer from './Footer';

const PrimaryLayout = ({
    children,
    metadata,
    title,
    description,
    image,
    site,
    environment,
    pageType,
}) => {
    return (
        <>
            <SEO
                title={title}
                description={description}
                metadata={metadata}
                image={image}
                article={pageType === 'single-post' ? true : false}
            />
            <div id="wrapper">
                <Header
                    site={site}
                    environment={environment}
                    siteTitle={metadata.defaultTitle}
                    title={title}
                />
                {children}
                <Footer />
            </div>
        </>
    );
};

export default PrimaryLayout;
