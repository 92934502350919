import React, { Component } from 'react';
import { Link } from 'gatsby';
import { NavDropHolder } from './BahaideNavigation';
import Helmet from 'react-helmet';

class AktuellesNavigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileNav: false,
        };
    }
    _toggleNav = e => {
        e.preventDefault();
        this.setState({
            mobileNav: !this.state.mobileNav,
        });
    };
    render() {
        return (
            <>
                <Helmet>
                    <body
                        className={this.state.mobileNav ? 'nav-active' : ''}
                    />
                </Helmet>
                <div className="nav-holder">
                    <a
                        href="#"
                        onClick={e => this._toggleNav(e)}
                        className="nav-opener"
                    />
                    <div className="nav-drop">
                        <ul id="nav" className="menu">
                            <li>
                                <Link to="/archiv/">Archiv</Link>
                                <NavDropHolder>
                                    <Link className="link" to="/archiv/">
                                        Archiv
                                    </Link>
                                </NavDropHolder>
                            </li>
                            <li>
                                <Link to="/podcasts-videos/">
                                    Podcasts und Videos
                                </Link>
                                <NavDropHolder>
                                    <Link
                                        className="link"
                                        to="/podcasts-videos/"
                                    >
                                        Podcasts und Videos
                                    </Link>
                                </NavDropHolder>
                            </li>
                            {/* <li>
                                <Link to="/presse/">
                                    Presse Bereich
                                </Link>
                                <NavDropHolder>
                                    <Link
                                        className="link"
                                        to="/presse/"
                                    >
                                        Presse Bereich
                                    </Link>
                                </NavDropHolder>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

export default AktuellesNavigation;
